export default () => [
  [{
    id: '1-1',
    selected: true,
    rotation: 0,
  }, {
    id: '1-2',
    selected: false,
    rotation: 0,
  }, {
    id: '1-3',
    selected: false,
    rotation: 0,
  }, {
    id: '1-4',
    selected: false,
    rotation: 0,
  }],
  [{
    id: '2-1',
    selected: false,
    rotation: 0,
  }, {
    id: '2-2',
    selected: false,
    rotation: 0,
  }, {
    id: '2-3',
    selected: false,
    rotation: 0,
  }, {
    id: '2-4',
    selected: false,
    rotation: 0,
  }],
  [{
    id: '3-1',
    selected: false,
    rotation: 0,
  }, {
    id: '3-2',
    selected: false,
    rotation: 0,
  }, {
    id: '3-3',
    selected: false,
    rotation: 0,
  }, {
    id: '3-4',
    selected: false,
    rotation: 0,
  }],
];
