import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage, Link } from 'gatsby-plugin-intl';
import _find from 'lodash/find';
import _map from 'lodash/map';

import config from 'config';

import Switch from 'components/Switch';
import Configurator from 'components/Configurator';

import saveData from 'utils/saveData';
import getDefaultPanels from 'utils/getDefaultPanels';
import countPanels from 'utils/countPanels';
import getPrice from 'utils/getPrice';
import getDeliveryPrice from 'utils/getDeliveryPrice';
import getGroupedPanels from 'utils/getGroupedPanels';
import sendCheckoutStartEvent from 'utils/sendCheckoutStartEvent';

import './ConfiguratorPageStep2.scss';

const ConfiguratorPageStep2 = ({ typeId }) => {
  const [panels, setPanels] = useState(getDefaultPanels());
  const [changingPanelType, setChangingPanelType] = useState(false);
  const [selectedPanelType, setSelectedPanelType] = useState(typeId || config.panelTypes[0].id);
  const [gridHidden, setGridHidden] = useState(false);
  const selectedPanel = _find(config.panelTypes, { id: selectedPanelType });

  const amountOfPanels = countPanels(panels);
  const deliveryPrice = getDeliveryPrice(amountOfPanels);

  return (
    <div className="configurator-page-step-2">
      <div className="two-columns inline">
        <div className="left">
          <div className="line" />
          <h1>
            <FormattedMessage id="configuratorStep2Configurator" />
          </h1>
          <div className="show-grid always-inline">
            <span className="label">
              <FormattedMessage id="configuratorStep2HideGrid" />
            </span>
            <Switch
              checked={gridHidden}
              onChange={setGridHidden}
            />
          </div>
          <div className="line" />
          <div className="price always-inline">
            <span className="label">
              <FormattedMessage id="configuratorStep2Price" />
            </span>
            <div className="bold">
              {getPrice(amountOfPanels, selectedPanelType)}
              <span className="left-pad">
                <FormattedMessage id="configuratorStep2Currency" />
              </span>
            </div>
          </div>
          <div className="panel-type always-inline">
            <span className="label">
              <FormattedMessage id="configuratorStep2Chosen" />
            </span>
            <div className="bold">
              {selectedPanel.name}
            </div>
            {
              !changingPanelType && (
                <div className="button">
                  <a
                    onClick={() => {
                      setChangingPanelType(true);
                    }}
                  >
                    <FormattedMessage id="configuratorStep2Change" />
                  </a>
                </div>
              )
            }
          </div>
          <div
            className={classNames('change-panel-type-wrapper', {
              hidden: !changingPanelType,
            })}
          >
            {
              _map(getGroupedPanels(config.panelTypes), (el, key) => (
                <div key={key} className="group-wrapper">
                  <div className="group-name">
                    {key}
                  </div>
                  <div className="items">
                    {
                      _map(el, (el1) => (
                        <div
                          key={el1.id}
                          className="panel-type-image"
                          onClick={() => {
                            setSelectedPanelType(el1.id);
                            setChangingPanelType(false);
                          }}
                        >
                          <img src={`/images/${el1.image}`} alt="" className="img-responsive" />
                        </div>
                      ))
                    }
                  </div>
                </div>
              ))
            }
          </div>
          <div className="panel-amount always-inline">
            <span className="label">
              <FormattedMessage id="configuratorStep2SAmount" />
            </span>
            <div className="bold">
              {amountOfPanels}
              <span className="left-pad">
                <FormattedMessage id="configuratorStep2Pieces" />
              </span>
            </div>
          </div>
          <div className="delivery-price always-inline">
            <span className="label">
              <FormattedMessage id="dhlDelivery" />
            </span>
            <div className="bold">
              {
                deliveryPrice ? (
                  <>
                    {deliveryPrice}
                    <span className="left-pad">
                      {config.currency}
                    </span>
                  </>
                ) : (
                  <FormattedMessage id="forFree" />
                )
              }
            </div>
          </div>
          <div className="realization-time always-inline">
            <span className="label">
              <FormattedMessage id="realizationLabel" />
            </span>
            <div className="bold">
              {config.realizationDays}
              <span className="left-pad">
                <FormattedMessage id="realizationDays" />
              </span>
            </div>
          </div>
          <div className="btn-wrapper">
            <Link
              to="/configurator/step-3"
              state={{ typeId: selectedPanelType }}
              className="btn"
              onClick={() => {
                saveData('configuration', panels);
                sendCheckoutStartEvent(selectedPanelType, amountOfPanels);
              }}
            >
              <FormattedMessage id="configuratorStep2Order" />
            </Link>
          </div>
        </div>
        <div className="right">
          <Configurator
            panels={panels}
            onPanelChange={setPanels}
            gridVisible={!gridHidden}
            selectedPanelType={selectedPanelType}
            disableDelete={amountOfPanels <= 1}
          />
        </div>
      </div>
    </div>
  );
};

ConfiguratorPageStep2.propTypes = {
  typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ConfiguratorPageStep2.defaultProps = {
  typeId: null,
};

export default ConfiguratorPageStep2;
