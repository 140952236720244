import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import './Switch.scss';

const Switch = ({ checked, onChange }) => (
  <div
    className={classnames('custom-switch', {
      active: checked,
    })}
    onClick={() => {
      onChange(!checked);
    }}
  >
    <div
      className={classnames('dot', {
        active: checked,
      })}
    />
  </div>
);

Switch.propTypes = {
  onChange: PropTypes.func.isRequired,
  checked: PropTypes.bool,
};

Switch.defaultProps = {
  checked: false,
};

export default Switch;
