import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import _findIndex from 'lodash/findIndex';

import './Configurator.scss';

const rotate = (panels, onPanelChange, index, index1) => {
  const currentRotation = panels[index][index1].rotation;
  let newRotation = 0;

  if (currentRotation !== 3) {
    newRotation = currentRotation + 1;
  }

  const newPanels = [
    ...panels,
  ];

  newPanels[index][index1].rotation = newRotation;

  onPanelChange(newPanels);
};

const remove = (panels, onPanelChange, index, index1) => {
  const newPanels = [
    ...panels,
  ];

  newPanels[index][index1].rotation = 0;
  newPanels[index][index1].selected = false;

  onPanelChange(newPanels);
};

const add = (panels, onPanelChange, index, index1) => {
  const newPanels = [
    ...panels,
  ];

  newPanels[index][index1].rotation = 0;
  newPanels[index][index1].selected = true;

  onPanelChange(newPanels);
};

const Configurator = ({
  panels, onPanelChange, gridVisible, selectedPanelType, disableDelete,
}) => (
  <div className="configurator">
    <img src="/images/configurator-bg.jpg" className="img-responsive" alt="" />
    <div className="panels-wrapper">
      {
        panels.map((el, index) => (
          <div
            className={classnames('row', {
              selectedChildren: _findIndex(el, { selected: true }) > -1,
            })}
            key={`panel-row-${index}`}
          >
            {
                el.map((el1, index1) => (
                  <div
                    className={classnames('panel', {
                      active: el1.selected,
                      withGrid: gridVisible && !el1.selected,
                    })}
                    key={el1.id}
                  >
                    <img src={`/images/configurator/${selectedPanelType}/${el1.rotation}.png`} className="img-responsive real-image" alt="" />
                    <img src={`/images/configurator/${selectedPanelType}/0.png`} className="img-responsive preview" alt="" />
                    <img src="/images/grid-bg-new.png" className="img-responsive grid" alt="" />
                    <img src="/images/grid-bg-left.png" className="grid-left" alt="" />
                    {
                      index === (panels.length - 1) && index1 !== (el.length - 1) && (
                        <div className="length-label position-bottom">
                          {index1 + 1}
                          m
                        </div>
                      )
                    }
                    {
                      index1 === (el.length - 1) && (
                        <div className="length-label position-right">
                          {panels.length - index}
                          m
                        </div>
                      )
                    }
                    <div className="actions">
                      <div
                        className="action rotate"
                        onClick={() => {
                          rotate(panels, onPanelChange, index, index1);
                        }}
                      >
                        <img src="/images/rotate-icon.png" alt="" />
                      </div>
                      <div
                        className={classnames('action', 'delete', {
                          hidden: disableDelete,
                        })}
                        onClick={() => {
                          remove(panels, onPanelChange, index, index1);
                        }}
                      >
                        <img src="/images/delete-icon.png" alt="" />
                      </div>
                      <div
                        className="action add"
                        onClick={() => {
                          add(panels, onPanelChange, index, index1);
                        }}
                      >
                        <img src="/images/add-icon.png" alt="" />
                      </div>
                    </div>
                  </div>
                ))
            }
          </div>
        ))
      }
    </div>
  </div>
);

Configurator.propTypes = {
  panels: PropTypes.arrayOf(PropTypes.array).isRequired,
  selectedPanelType: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  onPanelChange: PropTypes.func.isRequired,
  disableDelete: PropTypes.bool,
  gridVisible: PropTypes.bool,
};

Configurator.defaultProps = {
  disableDelete: false,
  gridVisible: false,
};

export default Configurator;
