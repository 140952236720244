import React from 'react';
import PropTypes from 'prop-types';
import _get from 'lodash/get';

import ConfiguratorPageStep2 from 'components/ConfiguratorPageStep2';

const ConfiguratorStep2 = ({ location }) => {
  const typeId = _get(location, 'state.typeId', null);

  return (
    <ConfiguratorPageStep2 typeId={typeId} />
  );
};

ConfiguratorStep2.propTypes = {
  location: PropTypes.shape({
    state: PropTypes.shape({
      typeId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    }),
  }).isRequired,
};

export default ConfiguratorStep2;
