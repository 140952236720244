import getCheckoutData from 'utils/getCheckoutData';

const sendCheckoutStartEvent = (selectedPanelType, amountOfPanels) => {
  if (typeof window === 'undefined') {
    return;
  }

  window.dataLayer.push({
    event: 'checkout_start',
    price: getCheckoutData(selectedPanelType, amountOfPanels),
  });
};

export default sendCheckoutStartEvent;
